<template>
    <div>
        <table border="1" cellspacing="0">
            <thead>
                <tr>
                    <td>
                        123
                    </td>
                    <td>
                        123
                    </td>
                    <td>
                        123
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        123
                    </td>
                    <td>
                        123
                    </td>
                    <td>
                        123
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    methods: {

    }

}
</script>

<style lang="scss" scoped>
table,
table tr th,
table tr td {
    border: 1px solid #000;
}

table {
    width: 100%;
    min-height: 25px;
    line-height: 25px;
    text-align: center;
    border-collapse: collapse;
}</style>